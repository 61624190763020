.progress-bar-circle {
  position: relative;
  height: 200px;
  width: 200px;
}

.progress-bar-circle div {
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.progress-bar-circle div span {
  position: absolute;
  font-size: 25px;
  line-height: 175px;
  height: 175px;
  width: 175px;
  left: 12.5px;
  top: 12.5px;
  text-align: center;
  border-radius: 50%;
  background-color: white;
}

.progress-bar-circle .background { background-color: #b3cef6; }

.progress-bar-circle .rotate {
  clip: rect(0 100px 200px 0);
  background-color: #4b86db;
}

.progress-bar-circle .left {
  clip: rect(0 100px 200px 0);
  opacity: 1;
  background-color: #b3cef6;
}

.progress-bar-circle .right {
  clip: rect(0 100px 200px 0);
  transform: rotate(180deg);
  opacity: 0;
  background-color: #4b86db;
}
 @keyframes 
toggle {  0% {
 opacity: 0;
}
 100% {
 opacity: 1;
}
}
